<!-- =========================================================================================
    File Name: Error404.vue
    Description: 404 Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="h-screen flex w-full">
    <div
      class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-1/2 mx-auto"
    >
      <img
        src="@/assets/images/logo/foto.jpeg"
        alt="graphic-404"
        class="mx-auto mb-4 max-w-full"
      />
      <vs-card>
        <h1 class="sm:mx-0 mx-4 mb-12 text-5xl d-theme-heading-color">
          Redefinição de senha - Sirius
        </h1>
        <form>
          <fieldset>
            <legend>Informe sua nova senha</legend>
            <p>Nova senha:</p>
            <vs-input
              v-validate="'required'"
              name="newPass"
              v-model="newPass"
              data-vv-as="Nova Senha"
              type="password"
            ></vs-input>
            <p>Confirmar Nova senha:</p>
            <vs-input
              v-validate="'required'"
              name="newPassConfirm"
              v-model="newPassConfirm"
              data-vv-as="Nova Senha"
              type="password"
            ></vs-input>
            <br />
            <vs-button class="mr-6" @click="updatePassword()"
              >Alterar</vs-button
            >
          </fieldset>
        </form>
      </vs-card>
    </div>
  </div>
</template>
<script>
import siriusAPI from "../services";
import { notifyError, notifySuccess } from "../utils/helpers";
export default {
  data() {
    return {
      uuid: null,
      newPassConfirm: null,
      newPass: null,
    };
  },
  mounted() {
    this.uuid = this.$route.query.token;
  },
  methods: {
    async updatePassword() {
      if (this.newPassConfirm == this.newPass && this.newPass) {
        const data = {
          uuid: this.uuid,
          password: this.newPass,
        };

        this.$vs.loading();
        await new siriusAPI()
          .saveForgotPassword(data)
          .then(() => {
            this.newPass = null;
            this.newPassConfirm = null;
            this.uuid = null;
            this.$vs.loading.close();
            notifySuccess(
              this,
              "Senha alterada com sucesso! Efetue o login com a sua nova senha!"
            );

            this.$router.push("/login");
          })
          .catch((error) => {
            this.$vs.loading.close();
            if (error.response.data.statusCode == "401") {
              localStorage.clear();
              sessionStorage.removeItem("sirius-key");
              this.$router.push("/").catch(() => {});
              this.$acl.change(["public"]);
            } else {
              notifyError(
                this,
                "Erro ao tentar alterar a senha: " + error.response.data.message
              );
            }
          });
      } else {
        notifyError(this, "Senhas não conferem, favor corrigir");
      }
    },
  },
};
</script>
<style scoped>
fieldset {
  border: 0.8px solid #b6b2b2;
  padding: 1em;
}
fieldset legend {
  font-weight: bold;
}
</style>
